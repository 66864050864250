/* src/components/Register.css */

.register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
  }
  
  .register-paper {
    padding: 32px;
    max-width: 400px;
    width: 100%;
  }
  
  .register-title {
    text-align: center;
    margin-bottom: 24px;
  }
  
  .register-form {
    display: flex;
    flex-direction: column;
  }
  
  .register-input {
    margin-bottom: 16px;
  }
  
  .register-button {
    margin-top: 16px;
  }
  