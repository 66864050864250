/* index.css */

/* Global styles */
body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
  }
  
  h1, h2, h3, h4, h5, h6 {
    color: #333;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  /* Ensure all content is responsive */
  * {
    box-sizing: border-box;
  }
  
  .container {
    padding: 20px;
  }
  